import React, {useContext} from "react"
import "./WorkExperience.scss"
import ExperienceCard from "../../components/experienceCard/ExperienceCard"
import StyleContext from "../../contexts/StyleContext"
import emoji from "react-easy-emoji"
import django from "../../assets/images/django.svg"

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext)

  const workExperiences = {
    display: true,
    experience: [
      {
        role: "Software Engineer",
        company: "Barclays",
        companylogo: require("../../assets/images/barclays.png"),
        date: "July 2023 – Present",
        desc: "Working as an Engineer in Reference Data Services (Markets Pre Trade Department)",
        descBullets: [
          emoji("⚡ Built a utility app for grid-format API data with advanced search, sorting, and CSV download."),
          emoji("⚡ Used JUnit and Mockito for thorough testing, increasing code coverage from 4% to 72%, with SonarQube ensuring code quality."),
          emoji("⚡ Engineered a cache service wrapper for GridGain and Coherence, introducing a dynamic mechanism for efficient cache serialization and deserialization to streamline restoration processes."),
          emoji("⚡ Migrated the application from an on-prem server to OpenShift using Docker and OpenShift (APAAS), managing configurations via OC CLI."),
          emoji("⚡ Automated 60-day password rotation using Shell Script and Java, updating keytabs, secrets, databases and files."),
          emoji("⚡ Migrated five RDS applications from Tomcat 7 and 8 to Tomcat 9 across environments using Chef automation."),
          emoji("⚡ Developing an enrichment service layer on AWS to transform JSON data from new systems into XML for legacy systems using AWS components such as Lambda, EC2, S3, OpenSearch, ElastiCache, ECS, along with Kafka and Solace for messaging."),
        ],
        technologiesUsed : [
          {
            skillName: "Angular",
            imageLink: require("../../assets/images/angular.png")
          },
          {
            skillName: "React",
            imageLink: require("../../assets/images/react.png")
          },
          {
            skillName: "Java",
            imageLink: require("../../assets/images/java.png")
          },
          {
            skillName: "Spring",
            imageLink: require("../../assets/images/spring.webp")
          },
          {
            skillName: "Node JS",
            imageLink: require("../../assets/images/nodejs.png")
          },
          {
            skillName: "Bitbucket",
            imageLink: require("../../assets/images/bitbucket.png")
          },
          {
            skillName: "Jira",
            imageLink: require("../../assets/images/jira.png")
          },
          {
            skillName: "Confluence",
            imageLink: require("../../assets/images/confluence.webp")
          },
          {
            skillName: "SQL Server",
            imageLink: require("../../assets/images/sql-server.png")
          },
          {
            skillName: "JUnit",
            imageLink: require("../../assets/images/junit.png")
          },
          {
            skillName: "AWS",
            imageLink: require("../../assets/images/aws.webp")
          },
          {
            skillName: "Chef",
            imageLink: require("../../assets/images/chef.png")
          },
          {
            skillName: "Mockito",
            imageLink: require("../../assets/images/mockito.png")
          },
          {
            skillName: "Hadoop",
            imageLink: require("../../assets/images/hadoop.png")
          },
          {
            skillName: "Kafka",
            imageLink: require("../../assets/images/kafka.png")
          },
          {
            skillName: "Swagger",
            imageLink: require("../../assets/images/swagger.png")
          },
          {
            skillName: "Jacoco",
            imageLink: require("../../assets/images/jacoco.png")
          },
          {
            skillName: "Sonarqube",
            imageLink: require("../../assets/images/sonarqube.png")
          },
        ]
      },
      {
        role: "Software Developer",
        company: "NxtBig Software Labs Pvt. Ltd.",
        companylogo: require("../../assets/images/nxtbig.png"),
        date: "March 2023 – June 2023",
        desc: "Worked as a Full Stack Software Developer",
        descBullets: [
          emoji("⚡ Explored headless e-commerce platforms like Medusa.js, Saleor.io, Vendure, and Zoho Books."),
          emoji("⚡ Designed the database using Prisma ORM and implemented it with Supabase, ensuring secure authentication and authorization."),
          emoji("⚡ Developed an interactive UI using Next.js for applications and built REST APIs to assist in CRUD operations."),
          emoji("⚡ Integrated the application with Zoho CRM, Zoho Books, and Zoho Desk by creating webhooks and triggers for various events to synchronize user data across all four systems."),
          emoji("⚡ Curated form data based on ITR filing requirements provided by the Government of India and converted it into interactive UI forms to assist users in filing their ITR based on responses selected in previous form pages."),
        ],
        technologiesUsed : [
          {
            skillName: "HTML 5",
            imageLink: require("../../assets/images/html5.png")
          },
          {
            skillName: "CSS 3",
            imageLink: require("../../assets/images/css3.png")
          },
          {
            skillName: "JavaScript",
            imageLink: require("../../assets/images/js.png")
          },
          {
            skillName: "Typescript",
            imageLink: require("../../assets/images/typescript.png")
          },
          {
            skillName: "Next.js",
            imageLink: require("../../assets/images/nextjs.png")
          },
          {
            skillName: "Supabase",
            imageLink: require("../../assets/images/supabase.png")
          },
          {
            skillName: "Prisma ORM",
            imageLink: require("../../assets/images/prisma.png")
          },
        ]
      },
      {
        role: "Summer Intern",
        company: "Barclays",
        companylogo: require("../../assets/images/barclays.png"),
        date: "June 2022 – August 2022",
        desc: "Worked as a Test Automation Engineer",
        descBullets: [
          emoji("⚡ Part of the Markets Pre-Trade Department."),
          emoji("⚡ Tested hundreds of scenarios for applications developed by the IT Web Portal Department."),
          emoji("⚡ Used Cucumber.js and Cypress.io testing frameworks to facilitate behavior-driven development and ensure applications met the desired specifications and functionality."),
        ],
        technologiesUsed : [
          {
            skillName: "HTML 5",
            imageLink: require("../../assets/images/html5.png")
          },
          {
            skillName: "CSS 3",
            imageLink: require("../../assets/images/css3.png")
          },
          {
            skillName: "JavaScript",
            imageLink: require("../../assets/images/js.png")
          },
          {
            skillName: "Cypress.io",
            imageLink: require("../../assets/images/cypress.png")
          },
          {
            skillName: "Cucumber JS",
            imageLink: require("../../assets/images/cucumber.png")
          },
          {
            skillName: "Selenium",
            imageLink: require("../../assets/images/selenium.png")
          },
          {
            skillName: "Bitbucket",
            imageLink: require("../../assets/images/bitbucket.png")
          },
        ]
      },
      {
        role: "Technical Intern",
        company: "Intract Technologies",
        companylogo: require("../../assets/images/intract-technologies.png"),
        date: "February 2022 – April 2022",
        desc: "Worked as a Full Stack Developer",
        descBullets: [
          emoji("⚡ Collaborated with the team to develop new features for ERP, mobile, and web applications."),
          emoji("⚡ Designed and developed an SMS dashboard system for the ERP application."),
          emoji("⚡ Converted template DOCX files to PDF with appropriate data and then to a base64 string for an efficient solution."),
        ],
        technologiesUsed : [
          {
            skillName: "Vue JS",
            imageLink: require("../../assets/images/vuejs.png")
          },
          {
            skillName: "Vuetify",
            imageLink: require("../../assets/images/vuetify.png")
          },
          {
            skillName: "Python",
            imageLink: require("../../assets/images/python.png")
          },
          {
            skillName: "Django",
            imageLink: django
          },
          {
            skillName: "REST Api",
            imageLink: require("../../assets/images/rest.png")
          },
        ]
      },
      {
        role: "Web Development Intern",
        company: "K J Somaiya College of Engineering",
        companylogo: require("../../assets/images/somaiya.png"),
        date: "February 2021 – April 2021",
        desc: "Worked as a Front-End Developer",
        descBullets: [
          emoji("⚡ Developed and conceptualized the theme for the university's cultural festival, Symphony 2021."),
          emoji("⚡ Collaborated with the team to create wireframes, mockups, and theme-appropriate colors and fonts."),
          emoji("⚡ Designed creative strategies to ensure a responsive website that functions well on all screen sizes."),
          emoji("⚡ Integrated user-friendly forms for participation in various live-streamed events."),
          emoji("⚡ Utilized various JavaScript libraries to develop websites with animations, enhancing the user experience."),
        ],
        technologiesUsed : [
          {
            skillName: "HTML5",
            imageLink: require("../../assets/images/html5.png")
          },
          {
            skillName: "CSS3",
            imageLink: require("../../assets/images/css3.png")
          },
          {
            skillName: "JavaScript",
            imageLink: require("../../assets/images/js.png")
          }
        ]
      },
    ]
  }

  return (
    <div id="experience">
        <div className="experience-container" id="workExperience">
          <div>
            <h1 className="experience-heading">{"Work Experience 👔"}</h1>
            <div className="experience-cards-div">
              {workExperiences.experience.map((card, i) => {
                return (
                  <ExperienceCard
                    key={i}
                    isDark={isDark}
                    cardInfo={{
                      company: card.company,
                      desc: card.desc,
                      date: card.date, 
                      companylogo: card.companylogo,
                      role: card.role,
                      descBullets: card.descBullets,
                      technologies: card.technologiesUsed
                    }}
                  />
                )
              })}
            </div>
          </div>
        </div>
    </div>
  )
}