import React, {useContext} from "react"
import {Fade} from "react-reveal"
import "./Greeting.scss"
import SocialMedia from "../../components/socialMedia/SocialMedia"
import Button from "../../components/button/Button"
import StyleContext from "../../contexts/StyleContext"
import manontable from "../../assets/images/manOnTable.svg"

export default function Greeting() {
  const {isDark} = useContext(StyleContext)
  return (
    <Fade bottom duration={2000} distance="50px" >
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className={isDark ? "dark-mode greeting-text" : "greeting-text"}>
                {"Hey! I'm Keval Sanghvi"}
              </h1>
              <p className={isDark ? "dark-mode greeting-text-p" : "greeting-text-p subTitle"}>
                {"Results-driven engineer with a fervor for developing innovative programs to boost organizational efficiency and success, eager to stay abreast of emerging technologies and contribute proactively to organizational goals."}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                <Button
                  text="My Resume"
                  newTab={true}
                  href="https://drive.google.com/file/d/1LlyXdiBwC6nyDHhdL8lVu4DTJSSKcviE/view?usp=sharing"
                />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
              <img
                className="mainPageLogo"
                alt="man sitting on table"
                src={manontable} />
          </div>
        </div>
      </div>
    </Fade>
  )
}